import React from 'react'
import { Link } from 'gatsby'

import { logEvent } from '../utils/analytics'

const footerSocialClass = 'link dim w2-5 pa2 dib relative v-mid ml0 ml2-ns'
const footerLinks = 'dib relative w-auto f7 gray link dim'
const year = new Date().getFullYear()

const Footer = class extends React.Component {
  constructor(props) {
    super(props)

    this.logClick = this.logClick.bind(this)
  }

  logClick(name) {
    const event = {
      category: 'Social',
      action: `Clicked ${name}`
    }
    logEvent(event)
  }

  render() {
    const { addressLineTwo, address, email, name, city, zip } = this.props
    return (
      <footer className="dib relative w-100 bg-white pv5 fw5 lh-copy footer">
        <div className="center mw8 ph4 pv0 tl">
          <div className="row top-xs">
            <div className="col-xs-12 col-md-8">
              <div className="dib relative w-100">
                <a
                  className="link b f4 f3-ns dim black"
                  onClick={() => {
                    this.logClick('Email')
                  }}
                  href={`mailto:${email}`}
                  rel="noopener noreferrer"
                >
                  {email}
                </a>
                <p className="f6 db ttu mb3">
                  &copy; {year} {name}
                </p>
                <a
                  className="dib relative w-auto dark-gray link dim f6 mb4"
                  onClick={() => {
                    this.logClick('Address')
                  }}
                  target="_blank"
                  href="https://goo.gl/maps/ii4jkhvHTsg4ooJ8A"
                  rel="noopener noreferrer"
                >
                  {address} {addressLineTwo}
                  <br /> {city} {zip}
                </a>
                <div className="dib relative w-100 mb4 mb0-l">
                  <Link className={footerLinks} to="/terms">
                    Terms of Service
                  </Link>
                  <span className="dib relative v-mid mh2 gray f7">•</span>
                  <Link className={footerLinks} to="/privacy">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-4 tl tr-ns">
              <div className="dib relative w-100 links mb0 mb4-ns">
                <div className="dib relative w-100">
                  <a
                    className={footerSocialClass}
                    onClick={() => {
                      this.logClick('Instagram')
                    }}
                    target="_blank"
                    title="Instagram"
                    href="https://www.instagram.com/lakefinance/"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="dib relative w-100"
                      alt="Instagram"
                      src="/icons/social/instagram.svg"
                    />
                  </a>
                  <a
                    className={footerSocialClass}
                    onClick={() => {
                      this.logClick('LinkedIn')
                    }}
                    target="_blank"
                    title="LinkedIn"
                    href="https://www.linkedin.com/company/lakefinance/"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="dib relative w-100"
                      alt="LinkedIn"
                      src="/icons/social/linkedin.svg"
                    />
                  </a>
                  <a
                    className={footerSocialClass}
                    onClick={() => {
                      this.logClick('Twitter')
                    }}
                    target="_blank"
                    title="Twitter"
                    href="https://twitter.com/lakefinance/"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="dib relative w-100"
                      alt="Twitter"
                      src="/icons/social/twitter.svg"
                    />
                  </a>
                  <a
                    className={footerSocialClass}
                    onClick={() => {
                      this.logClick('Discord')
                    }}
                    target="_blank"
                    title="Discord"
                    href="https://discord.gg/vHfcRcd"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="dib relative w-100"
                      alt="Discord"
                      src="/icons/social/discord.svg"
                    />
                  </a>
                  <a
                    className={footerSocialClass}
                    onClick={() => {
                      this.logClick('Medium')
                    }}
                    target="_blank"
                    title="Medium"
                    href="https://medium.com/lakefinance"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="dib relative w-100"
                      alt="Medium"
                      src="/icons/social/medium.svg"
                    />
                  </a>
                </div>
                <div className="dib relative w-100">
                  <a
                    className={`${footerLinks} pa2`}
                    target="_blank"
                    href="https://www.hughlangis.com"
                  >
                    Illustrations by Hugh Langis
                    <img
                      className="dib relative v-mid w1 h1 fit ml2 br-pill"
                      src="/team/hugh.jpg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
