import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const detailsQuery = graphql`
  query SEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      author
    }
  }
`

function SEO({ description, lang, meta, keywords = [], title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        if (!data.site) {
          return
        }
        const metaDescription = description || data.site.description
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={title === data.site.title ? '%s' : `%s | ${data.site.title}`}
            // Link
            link={[
              {
                href: 'https://fonts.googleapis.com/css?family=Karla:400,700&display=swap',
                rel: 'stylesheet'
              },
              {
                href: 'https://lake.finance/favicons/apple-touch-icon.png',
                sizes: '180x180',
                rel: 'apple-touch-icon'
              },
              {
                href: 'https://lake.finance/favicons/favicon-32x32.png',
                sizes: '32x32',
                type: 'image/png',
                rel: 'icon'
              },
              {
                href: 'https://lake.finance/favicons/favicon-16x16.png',
                sizes: '16x16',
                type: 'image/png',
                rel: 'icon'
              },
              {
                href: 'https://lake.finance/favicons/site.webmanifest',
                rel: 'manifest'
              },
              {
                href: 'https://lake.finance/favicons/safari-pinned-tab.svg',
                color: '#ea4439',
                rel: 'mask-icon'
              },
              {
                href: 'https://lake.finance/favicons/favicon.ico',
                rel: 'shortcut icon'
              }
            ]}
            // Metadata
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:image',
                content: 'https://lake.finance/img/social.png'
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: data.site.author
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              },
              {
                content: '#da532c',
                name: 'msapplication-TileColor'
              },
              {
                content: 'https://lake.finance/favicons/browserconfig.xml',
                name: 'msapplication-config'
              },
              {
                content: '#ea4439',
                name: 'theme-color'
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', ')
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
