import ReactGA from 'react-ga'
import axios from 'axios'

export const initGA = () => {
  // console.log('GA init', process.env.GATSBY_GA_ID)
  ReactGA.initialize(process.env.GATSBY_GA_ID || `UA-117934837-1`)
}
export const logPageView = () => {
  // console.log(`Logging pageview for ${window.location.pathname}`)
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}
export const logEvent = object => {
  if (object) {
    ReactGA.event(object)
  }
}
export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal })
  }
}
export const logIP = () => {
  axios
    .get('https://api6.ipify.org?format=json')
    .then(response => {
      const ip = response.data.ip
      const object = {
        category: 'IP',
        action: ip
      }
      logEvent(object)
    })
    .catch(error => {
      console.log(error)
    })
}
