import React from 'react'

import { initGA, logPageView, logIP } from '../utils/analytics'

import Header from './header'
import Footer from './footer'

import 'flexboxgrid'
import 'tachyons'

import '../styles/all.sass'

class Layout extends React.Component {
  componentDidMount() {
    // GA
    if (!window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }
    logPageView()
    // IP
    logIP()
    // Intercom
    this.initIntercom()
  }

  initIntercom() {
    // Intercom
    ;(function() {
      var w = window
      var ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        var d = document
        var i = function() {
          i.c(arguments)
        }
        i.q = []
        i.c = function(args) {
          i.q.push(args)
        }
        w.Intercom = i
        var l = function() {
          var s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://widget.intercom.io/widget/r3x3iff2'
          var x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })()
    // Boot it
    window.Intercom('boot', {
      app_id: 'r3x3iff2'
    })
  }

  render() {
    const { children, companyInfo, siteTitle } = this.props

    return (
      <>
        <Header siteTitle={siteTitle} />
        <div>{children}</div>
        <Footer
          addressLineTwo={companyInfo.address2}
          address={companyInfo.address1}
          country={companyInfo.country}
          email={companyInfo.email}
          name={companyInfo.name}
          city={companyInfo.city}
          zip={companyInfo.zipCode}
        />
      </>
    )
  }
}

export default Layout
