import React from 'react'
import { Link } from 'gatsby'

import { logEvent } from '../utils/analytics'

const LinkClass =
  'bg-transparent bn link dim dib relative w-100 w-auto-l inherit pa2 pv0-l ph0 ph2-l tl tc-l'
const footerSocialClass = 'link dim w2-5 pa2 dib relative v-mid ml0 ml2-ns'

const Header = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      isTop: true
    }

    this.isTopChecker = this.isTopChecker.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.logClick = this.logClick.bind(this)
  }

  isTopChecker() {
    const isTop = window.scrollY < 100
    if (isTop !== this.state.isTop) {
      this.setState({ isTop })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.isTopChecker)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.isTopChecker)
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  closeMenu() {
    if (this.state.menuOpen) {
      this.setState({
        menuOpen: false
      })
    }
  }

  logClick(action) {
    const event = {
      category: 'Get Started',
      action: `Clicked ${action}`
    }
    logEvent(event)
  }

  render() {
    const Logo = () => (
      <Link
        className="dib relative br-pill bg-brand-red link dim white logo tc"
        title="Lake"
        to="/"
      >
        <img className="dib relative h-100 w-100" alt="Lake" src="/img/logo-white.svg" />
      </Link>
    )

    const MiddleNav = () => (
      <div className="dib relative w-100 tl tc-l f3 f5-l">
        {/* <Link className={LinkClass} onClick={this.closeMenu} to="/performance">
          Performance
        </Link>
        <Link className={LinkClass} onClick={this.closeMenu} to="/pricing">
          Pricing
        </Link> */}
        <Link className={LinkClass} onClick={this.closeMenu} to="/">
          Invest
        </Link>
        <Link className={LinkClass} onClick={this.closeMenu} to="/environment">
          Environment
        </Link>
        <Link className={LinkClass} onClick={this.closeMenu} to="/community">
          Community
        </Link>
        <Link className={LinkClass} onClick={this.closeMenu} to="/about">
          About
        </Link>
      </div>
    )

    const RightNav = () => (
      <div className="dib relative w-100 w-auto-l">
        {/* Get started button */}
        <a
          className="dib relative w-100 w-auto-l bg-brand-pink brand-red tc ph3 pv2 br-pill b link dim pointer"
          onClick={() => {
            this.logClick('Get Started in Nav')
          }}
          target="_blank"
          href="https://app.lake.finance/"
        >
          Get started
        </a>
      </div>
    )

    const CloseButton = () => (
      <button
        className={`bg-transparent bn link dim dib dn-l relative w-100 w-auto-l inherit pa2`}
        onClick={this.closeMenu}
      >
        Close
      </button>
    )

    const MenuButton = () => (
      <button className="dib dn-l relative bn bg-transparent w-auto" onClick={this.toggleMenu}>
        Menu
      </button>
    )

    return (
      <nav
        aria-label="main-navigation"
        className={`navbar w-100 fixed left-0 top-0 dark-gray f5 z-2 ${
          !this.state.isTop ? 'bg-white ph4 pv2' : 'ph4 pv4'
        }`}
        role="navigation"
      >
        {/* Mobile nav */}
        <div
          className={`dib bg-brand-beige fixed left-0 top-0 z-3 w-100 h-100 mobile pa4 tl ${
            this.state.menuOpen ? 'open' : ''
          }`}
        >
          <div className="flex w-100 justify-between items-center">
            <Logo />
            <div className="dib dn-l relative tr">
              <CloseButton />
            </div>
          </div>
          <div className="dib relative w-100 mv4">
            <MiddleNav />
          </div>
          <div className="dib relative w-100 mb4">
            <RightNav />
          </div>
          <div className="dib relative w-100 absolute bottom-0 left-0">
            <div className="dib relative w-100 links pa3">
              <div className="dib relative w-100">
                <a
                  className={footerSocialClass}
                  target="_blank"
                  title="Instagram"
                  href="https://www.instagram.com/lakefinance/"
                  rel="noopener noreferrer"
                >
                  <img
                    className="dib relative w-100"
                    alt="Instagram"
                    src="/icons/social/instagram.svg"
                  />
                </a>
                <a
                  className={footerSocialClass}
                  target="_blank"
                  title="LinkedIn"
                  href="https://www.linkedin.com/company/lakefinance/"
                  rel="noopener noreferrer"
                >
                  <img
                    className="dib relative w-100"
                    alt="LinkedIn"
                    src="/icons/social/linkedin.svg"
                  />
                </a>
                <a
                  className={footerSocialClass}
                  target="_blank"
                  title="Twitter"
                  href="https://twitter.com/lakefinance"
                  rel="noopener noreferrer"
                >
                  <img
                    className="dib relative w-100"
                    alt="Twitter"
                    src="/icons/social/twitter.svg"
                  />
                </a>
                <a
                  className={footerSocialClass}
                  target="_blank"
                  title="Discord"
                  href="https://discord.gg/vHfcRcd"
                  rel="noopener noreferrer"
                >
                  <img
                    className="dib relative w-100"
                    alt="Discord"
                    src="/icons/social/discord.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Non-mobile nav */}
        <div className="db center mw8">
          <div className="flex w-100 justify-between items-center">
            {/* Left */}
            <Logo />
            {/* Middle */}
            <div className="dn dib-l relative v-mid tc">
              <MiddleNav />
            </div>
            {/* Right */}
            <div className="dn dib-l relative tr">
              <RightNav />
            </div>
            {/* Menu */}
            <div className="dib dn-l relative tr">
              <MenuButton />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
